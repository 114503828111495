import '../scss/styles.scss';
import '@babel/polyfill';
import queryString from 'query-string';
import './jquery-global';
import 'jquery.autokana';
import smoothscroll from 'smoothscroll-polyfill';
import { sideMenuManager } from './side-menu';
import { footerContactManager } from './footer-contact';
import { StorageButtonManager } from './storage-button-manager';
import { Keep } from './keep';
import { Favorite } from './favorite';
import { Collapse } from './collapse';
import {
  NotificationModal,
  RegionModal,
  SimpleModal,
  AttachDocumentsModal,
  SelectAttachDocumentMessageModal,
  NoDocumentsModal,
  CreateDocumentModal,
  DeleteDocumentModal,
  RenameDocumentModal,
  CopyDocumentModal,
  ApplyJobOfferDetailModal,
  IndeedEntryModal,
  ShigoTalkEntryModal,
  RecruitmentBoxEntryModal,
  RenameSearchConditionModal,
  DeleteSearchConditionModal,
  HideMessageModal,
} from './modules/modal';
import { HeadRoom } from './head-room';
import { FootBar } from './foot-bar';
import { ReadMore } from './read-more';
import { Accordion } from './accordion';
import { Tab } from './tab';
import { GridTab } from './grid-tab';
import { DateOptionsGenerator } from './date-options-generator';
import { CityOptionsGenerator } from './city-options-generator';
import { AddressFillFromPostalCode } from './address-fill-from-postalcode';
import { OnetimeDisplaySwitcher } from './onetime-display-switcher';
import { RefineSearch } from './refine-search';
import { AsyncJobOfferAggregation } from './async-job-offer-aggregation';
import { ScrollToTop, ScrollToReadStart, ScrollToTarget } from './scroll-to';
import { PreferredDisplayCampaign } from './preferred-display-campaign';
import { SearchConditionSaveForm } from './search-condition-save-form';
import { MessageForm } from './message-form';
import { FormManager } from './form-manager';
import { LinkExtender } from './link-extender';
import { RequiredQualificationByJobCategory, RequiredQualificationByJobCategoryWithValidation } from './required-qualification-by-job-category';
import { DuplicatedQualificationByJobCategory, DuplicatedQualificationByJobCategoryForValidateQualifications } from './duplicated-qualification-by-job-category';
import { CheckboxSynchronizer } from './checkbox-synchronizer';
import { Toggle } from './toggle';
import { Toaster } from './toaster';
import { Dropdown } from './dropdown';
import { MemberNotification } from './member-notification';
import { InputtedTextCounter } from './inputted-text-counter';
import { LazyImage } from './lazy-image';
import { emitter } from './util/event-emitter';
import { isIOS } from './util/device';
import { RestartGuidanceManager } from './restart-guidance-manager';
import { IOSDrumrollOmissionPreventer } from './ios-drumroll-omission-preventer';
import { FeatureReadMore } from './feature-read-more';

// jquery-global.js でwindowに生やしたjQueryを参照する
const $ = window.jQuery;

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
  }
  // hot reloadが効かないので入れておく
  // see: https://github.com/webpack/webpack-dev-server/issues/395#issuecomment-198532974
}

sideMenuManager();
// footer
footerContactManager();

class App {
  constructor() {
    this.refineSearches = [...document.querySelectorAll('.js-refine-search')].map((el) => {
      if ($(el).closest('#js-map-search').length) {
        return null;
      }
      const refineSearch = new RefineSearch(el);
      return refineSearch;
    });
    this.storageButtonManager = new StorageButtonManager({
      keep: new Keep(),
      favorite: new Favorite(),
    });
    this.storageButtonManager.build();
    this.asyncJobOfferAggregations = [...document.querySelectorAll('.js-job-offer-aggregation')].map((el) => {
      const asyncJobOfferAggregation = new AsyncJobOfferAggregation(el, {
        storageButtonManager: this.storageButtonManager,
      });
      return asyncJobOfferAggregation;
    });
    this.collapses = [...document.querySelectorAll('.js-collapse')].map((el) => {
      const collapse = new Collapse(el);
      return collapse;
    });
    this.headRoom = new HeadRoom('#js-header');
    this.footbar = new FootBar('#js-foot-bar', {
      upperLandmark: '.js-foot-bar-upper-landmark',
      lowerLandmark: '.js-foot-bar-lower-landmark',
    });
    this.secondFootbar = new FootBar('#js-second-foot-bar', {
      secondUpperLandmark: '.js-foot-bar-second-upper-landmark',
      lowerLandmark: '.js-foot-bar-second-lower-landmark',
    });
    this.readMores = [...document.querySelectorAll('.js-read-more')].map((el) => {
      const readMore = new ReadMore(el);
      return readMore;
    });
    this.featureReadMores = [...document.querySelectorAll('.js-feature-read-more')].map((el) => {
      const featureReadMore = new FeatureReadMore(el);
      return featureReadMore;
    });
    this.accordions = [...document.querySelectorAll('.js-accordion')].map((el) => {
      const accordion = new Accordion(el);
      return accordion;
    });
    this.dateOptionsGenerators = [...document.querySelectorAll('.js-date-options-generator')].map((el) => {
      const dateOptionsGenerator = new DateOptionsGenerator(el);
      return dateOptionsGenerator;
    });
    this.dropdown = new Dropdown();
    this.tabs = [...document.querySelectorAll('.js-tab')].map((el) => {
      const $el = $(el);
      const tab = new Tab(el, {
        navActiveClassName: $el.data('tab-nav-active-class-name'),
        contentActiveClassName: $el.data('tab-content-active-class-name'),
      });
      return tab;
    });
    this.gridTab = new GridTab('.js-grid-tab', {
      navActiveClassName: 'c-area-tab__item--active',
    });
    this.addressFillFromPostalCodes = [...document.querySelectorAll('.js-postal-code')].map((postalCode, index) => {
      const city = $('.js-city-select').eq(index)[0];

      return new AddressFillFromPostalCode({
        postalCode,
        prefecture: $('.js-prefecture-select').eq(index)[0],
        city,
        town: $('.js-town-input').eq(index)[0],
        cityOptionGenerator: new CityOptionsGenerator({
          prefecture: $('.js-prefecture-select').eq(index)[0],
          city,
        }),
        done: ({ $prefecture, $city }) => {
          if (!$prefecture.hasClass('js-form-manager__field')) {
            return;
          }
          emitter.emit('formManager:validateFields', {
            fields: [$prefecture, $city],
          });
        },
      });
    });
    this.onetimeDisplaySwitchers = [...document.querySelectorAll('.js-onetime-display-switcher')].map((el) => {
      const onetimeDisplaySwitcher = new OnetimeDisplaySwitcher(el);
      return onetimeDisplaySwitcher;
    });
    this.scrollToTop = new ScrollToTop();
    this.scrollToReadStart = new ScrollToReadStart();
    this.scrollToTarget = new ScrollToTarget();
    this.preferredDisplayCampaign = new PreferredDisplayCampaign();
    this.searchConditionSaveForm = new SearchConditionSaveForm('#js-search-condition-save-form', {
      notificationModal: new NotificationModal({
        modalId: 'notification',
        cookieId: 'search-condition-saved',
      }),
    });
    this.messageForm = new MessageForm({
      form: '#js-message-form',
      refusedScoutNotificationModal: new NotificationModal({ modalId: 'refused-scout-notification', cookieId: 'refused-scout-notification' }),
      noDocumentsModal: new NoDocumentsModal({ modalId: 'no-documents' }),
      attachDocumentsModal: new AttachDocumentsModal({ modalId: 'attach-documents' }),
    });

    const isThisIOS = isIOS();
    this.formManagers = [...document.querySelectorAll('.js-form-manager')].map((form) => {
      const formManager = new FormManager({
        form,
        errorClass: 'u-is-error',
        completedClass: 'js-is-completed',
        $counter: $('.js-form-manager-counter'),
        $total: $('.js-form-manager-total'),
        callback: {
          onFocus: () => {
            // iOS ではキーボード出現時にposition: fixed;の固定フッターが適切な位置に配置されず入力の妨げになりうる挙動をするので、キーボード出現中は固定フッターを非表示にしておく
            if (isThisIOS) {
              $('.js-form-manager-foot-bar').addClass('u-hidden');
            }
          },
          onBlur: () => {
            if (isThisIOS) {
              $('.js-form-manager-foot-bar').removeClass('u-hidden');
            }
          },
          onValidationMessageUpdated: ({ fields }) => {
            const triggers = fields.map((field) => {
              return $(field).closest('.js-accordion__item').find('.js-accordion__item-trigger');
            });
            emitter.emit('accordion:syncHeight', { triggers });
          },
        },
      });

      return formManager;
    });
    this.linkExtender = new LinkExtender();
    this.requiredQualificationByJobCategory = new RequiredQualificationByJobCategory('.js-required-qualification-by-job-category');
    this.requiredQualificationByJobCategoryWithValidation = new RequiredQualificationByJobCategoryWithValidation('.js-required-qualification-by-job-category-with-validation');
    this.duplicatedQualificationByJobCategory = new DuplicatedQualificationByJobCategory('.js-duplicated-qualification-by-job-category');
    this.duplicatedQualificationByJobCategoryForValidateQualifications = new DuplicatedQualificationByJobCategoryForValidateQualifications('.js-duplicated-qualification-by-job-category-with-validation');
    this.renameDocumentModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['rename-resume', 'rename-careersheet'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new RenameDocumentModal({
      modalId: $(el).data('modal-id'),
    }));
    this.copyDocumentModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['copy-resume'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new CopyDocumentModal({
      modalId: $(el).data('modal-id'),
    }));
    this.createDocumentModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['create-resume', 'create-careersheet', 'create-resume-careersheet'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new CreateDocumentModal({
      modalId: $(el).data('modal-id'),
    }));
    this.deleteDocumentModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['delete-document'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new DeleteDocumentModal({
      modalId: $(el).data('modal-id'),
    }));
    this.selectAttachDocumentMessageModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['select-attach-document-message'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new SelectAttachDocumentMessageModal({
      modalId: $(el).data('modal-id'),
    }));
    this.regionModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['search-region-1', 'search-region-2', 'search-region-3', 'search-region-4', 'search-region-5', 'search-region-6', 'search-region-7'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new RegionModal({
      modalId: $(el).data('modal-id'),
    }));
    this.simpleModals = [
      ...($('.js-modal').filter((_, el) => {
        return [
          'search-facility',
          'search-job-category',
          'contact-confirm',
          'job-offer-summary',
          'no1',
          'job-category-supported',
          'about-private',
          'search-condition-registration',
          'settings-withdrawal',
          'cannot-delete-document',
          'about-bank-code',
          'resume-import',
          'education-background-list-remove-confirm',
          'education-background-note-remove-confirm',
          'career-list-remove-confirm',
          'career-note-remove-confirm',
          'reason-for-applying-example',
          'desired-condition-example',
          'edit-and-duplicate',
          'resume-create-new',
          'signin-or-signup',
          'job_offer_search_histories',
        ].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new SimpleModal({
      modalId: $(el).data('modal-id'),
    }));
    this.renameSearchConditionModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['rename-search-condition'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new RenameSearchConditionModal({
      modalId: $(el).data('modal-id'),
    }));
    this.deleteSearchConditionModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['delete-search-condition'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new DeleteSearchConditionModal({
      modalId: $(el).data('modal-id'),
    }));
    this.toggles = [...document.querySelectorAll('.js-toggle')].map((wrapper) => {
      return new Toggle({ wrapper });
    });
    this.toaster = new Toaster('.js-toaster');
    this.memberNotification = new MemberNotification('.js-member-notification');
    this.applyJobofferDetailModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['mypage-joboffer-detail-modal', 'mypage-joboffer-detail-disabled-modal', 'mypage-joboffer-detail-info-modal'].includes($(el).data('modal-id'));
      })),
    ].map(el => new ApplyJobOfferDetailModal({
      modalId: $(el).data('modal-id'),
    }));
    this.hideMessageModals = [
      ...($('.js-modal').filter((_, el) => {
        return ['hide-message', 'show-message'].includes($(el).data('modal-id'));
      })).get(),
    ].map(el => new HideMessageModal({
      modalId: $(el).data('modal-id'),
    }));
    this.indeedEntryModal = new IndeedEntryModal({
      modalId: 'mypage-indeed-entry-modal',
    });
    this.shigoTalkEntryModal = new ShigoTalkEntryModal({
      modalId: 'mypage-shigo-talk-entry-modal',
    });
    this.recruitmentBoxEntryModal = new RecruitmentBoxEntryModal({
      modalId: 'mypage-recruitment-box-entry-modal',
    });
    this.inputtedTextCounters = Array.from(document.querySelectorAll('.js-inputted-text-counter'),
      counter => new InputtedTextCounter(counter),
    );
    this.restartGuidanceManager = new RestartGuidanceManager();
    this.iOSDrumrollOmissionPreventers = [...document.querySelectorAll('.js-ios-drumroll-omission-preventer')].map((el) => {
      const iOSDrumrollOmissionPreventer = new IOSDrumrollOmissionPreventer(el);
      return iOSDrumrollOmissionPreventer;
    });
  }

  init = () => {
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      beforeSend: (xhr, settings) => {
        const parsedUrl = queryString.parseUrl(settings.url);

        if (parsedUrl.url[parsedUrl.url.length - 1] === '/') {
          return;
        }
        if (Object.keys(parsedUrl.query).length > 0) {
          settings.url = `${ parsedUrl.url }/?${ queryString.stringify(parsedUrl.query) }`; // eslint-disable-line no-param-reassign
          return;
        }
        settings.url = `${ parsedUrl.url }/`; // eslint-disable-line no-param-reassign
      },
    });
    smoothscroll.polyfill();
    this.headRoom.init();
    this.footbar.init();
    this.secondFootbar.init();
    this.addressFillFromPostalCodes.map(addressFillFromPostalCode => addressFillFromPostalCode.init());
    this.refineSearches.map(refineSearch => refineSearch && refineSearch.init());
    this.collapses.map(collapse => collapse.init());
    this.readMores.map(readMore => readMore.init());
    this.featureReadMores.map(featureReadMore => featureReadMore.init());
    this.accordions.map(accordion => accordion.init());
    this.dateOptionsGenerators.map(dateOptionsGenerator => dateOptionsGenerator.init());
    this.tabs.map(tab => tab.init());
    this.dropdown.init();
    this.gridTab.init();
    this.onetimeDisplaySwitchers.map(onetimeDisplaySwitcher => onetimeDisplaySwitcher.init());
    this.scrollToTop.init();
    this.scrollToReadStart.init();
    this.scrollToTarget.init();

    // NOTE: formManagerのinit処理を待つ（registerSchemaの処理を待つ必要がある）
    Promise.all(this.formManagers.map(formManager => formManager.init())).then(() => {
      this.messageForm.init(this.formManagers);
    });

    [
      {
        input: '[name="member[family_name]"]',
        output: '[name="member[family_kana]"]',
      },
      {
        input: '[name="member[first_name]"]',
        output: '[name="member[first_kana]"]',
      },
      {
        input: '[name="inquiry[family_name]"]',
        output: '[name="inquiry[family_name_kana]"]',
      },
      {
        input: '[name="inquiry[first_name]"]',
        output: '[name="inquiry[first_name_kana]"]',
      },
    ].map(item => $.fn.autoKana(`input${ item.input }`, `input${ item.output }`));

    this.preferredDisplayCampaign.init();
    this.searchConditionSaveForm.init();
    this.linkExtender.init();
    this.asyncJobOfferAggregations.reduce((accumulator, current) => {
      return accumulator.then(() => {
        return current.render();
      });
    }, Promise.resolve());
    this.requiredQualificationByJobCategory.init();
    this.requiredQualificationByJobCategoryWithValidation.init();
    this.duplicatedQualificationByJobCategory.init();
    this.duplicatedQualificationByJobCategoryForValidateQualifications.init();
    this.renameDocumentModals.map(renameDocumentModal => renameDocumentModal.init());
    this.copyDocumentModals.map(copyDocumentModal => copyDocumentModal.init());
    this.createDocumentModals.map(createDocumentModal => createDocumentModal.init());
    this.deleteDocumentModals.map(deleteDocumentModal => deleteDocumentModal.init());
    this.selectAttachDocumentMessageModals.map(selectAttachDocumentMessageModal => selectAttachDocumentMessageModal.init());
    this.regionModals.map(regionModal => regionModal.init());
    this.simpleModals.map(simpleModal => simpleModal.init());
    this.renameSearchConditionModals.map(renameSearchConditionModal => renameSearchConditionModal.init());
    this.deleteSearchConditionModals.map(deleteSearchConditionModal => deleteSearchConditionModal.init());
    this.hideMessageModals.map(hideMessageModal => hideMessageModal.init());
    this.toggles.map(toggle => toggle.init());
    this.toaster.hide();

    $('.js-desired-job-category-checkbox-synchronizer').on('change', 'input:checkbox', e => CheckboxSynchronizer.sync(e.target, {
      type: 'value',
      form: $(e.target).closest('.js-desired-job-category-checkbox-synchronizer')[0],
    }));
    this.memberNotification.init();
    this.applyJobofferDetailModals.map(applyJobOfferModal => applyJobOfferModal.init());
    this.indeedEntryModal.init();
    this.shigoTalkEntryModal.init();
    this.recruitmentBoxEntryModal.init();
    this.inputtedTextCounters.map(counter => counter.init());
    this.restartGuidanceManager.init();
    this.iOSDrumrollOmissionPreventers.map(iOSDrumrollOmissionPreventer => iOSDrumrollOmissionPreventer.init());

    const pageViewID = $('.js-page-view-id').data('page-view-id');

    const route = new Promise((resolve) => {
      switch (pageViewID) {
        case 'facilities/detail':
          import('./pages/facility_detail' /* webpackChunkName: './pages/facility_detail' */).then(({ FacilityDetail }) => {
            new FacilityDetail().init();
            resolve();
          });
          break;
        case 'job_offers/detail':
        case 'job_offers/detail_map':
          import('./pages/job_offer_detail' /* webpackChunkName: './pages/job_offer_detail' */).then(({ JobOfferDetail }) => {
            new JobOfferDetail().init();
            resolve();
          });
          break;
        case 'job_offers/map_search':
          import('./search-by-map' /* webpackChunkName: './search-by-map' */).then(({ SearchByMap }) => {
            new SearchByMap({
              storageButtonManager: this.storageButtonManager,
            }).init();
            resolve();
          });
          break;
        case 'job_offers/index':
          import('./pages/job_offer_index' /* webpackChunkName: './pages/job_offer_index' */).then(({ JobOfferIndex }) => {
            new JobOfferIndex().init();
            resolve();
          });
          break;
        case 'members/index':
          import('./members-index' /* webpackChunkName: './members-index' */).then(({ MembersIndex }) => {
            new MembersIndex().init();
            resolve();
          });
          break;
        case 'members/messages':
        case 'members/messages/hidden':
          import('./members-messages' /* webpackChunkName: './message-swipe' */).then(({ MembersMessages }) => {
            new MembersMessages().init();
            resolve();
          });
          break;
        case 'profiles/index':
        case 'profiles/edit':
        case 'profile/additional/desired_cities/index':
          import('./members-profiles' /* webpackChunkName: './members-profiles' */).then(({ MembersProfiles }) => {
            new MembersProfiles({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'settings/index':
        case 'settings/edit_job_match_mail':
        case 'settings/edit_member_block_customer':
          import('./members-settings' /* webpackChunkName: './members-settings' */).then(({ MembersSettings }) => {
            new MembersSettings().init();
            resolve();
          });
          break;
        case 'static_pages/mission':
          import('./mission' /* webpackChunkName: './mission' */).then(({ Mission }) => {
            new Mission().init();
            resolve();
          });
          break;
        case 'static_pages/banner':
          import('./banner' /* webpackChunkName: './banner' */).then(({ Banner }) => {
            new Banner().init();
            resolve();
          });
          break;
        case 'job_offers/search':
          import('./pages/job_offer_search/index' /* webpackChunkName: './pages/job_offer_search/index' */).then(({ JobOfferSearch }) => {
            new JobOfferSearch().init();
            resolve();
          });
          break;
        case 'job_offers/pref':
          import('./pages/job_offer_search/pref' /* webpackChunkName: './pages/job_offer_search/pref' */).then(({ JobOfferSearchPref }) => {
            new JobOfferSearchPref().init();
            resolve();
          });
          break;
        case 'job_offers/city':
          import('./pages/job_offer_search/city' /* webpackChunkName: './pages/job_offer_search/city' */).then(({ JobOfferSearchCity }) => {
            new JobOfferSearchCity().init();
            resolve();
          });
          break;
        case 'job_offers/designated_city':
          import('./pages/job_offer_search/designated_city' /* webpackChunkName: './pages/job_offer_search/designated_city' */).then(({ JobOfferSearchDesignatedCity }) => {
            new JobOfferSearchDesignatedCity().init();
            resolve();
          });
          break;
        case 'job_offers/station':
          import('./pages/job_offer_search/station' /* webpackChunkName: './pages/job_offer_search/station' */).then(({ JobOfferSearchStation }) => {
            new JobOfferSearchStation().init();
            resolve();
          });
          break;
        case 'job_offers/search_keyword':
          import('./pages/job_offer_search/search_keyword' /* webpackChunkName: './pages/job_offer_search/search_keyword' */).then(({ JobOfferSearchKeyword }) => {
            new JobOfferSearchKeyword().init();
            resolve();
          });
          break;
        case 'job_offers/line':
          import('./pages/job_offer_search/line' /* webpackChunkName: './pages/job_offer_search/line' */).then(({ JobOfferSearchLine }) => {
            new JobOfferSearchLine().init();
            resolve();
          });
          break;
        case 'job_offers/feature':
          import('./pages/job_offer_search/feature' /* webpackChunkName: './pages/job_offer_search/feature' */).then(({ JobOfferSearchFeature }) => {
            new JobOfferSearchFeature().init();
            resolve();
          });
          break;
        case 'job_offers/employment':
          import('./pages/job_offer_search/employment' /* webpackChunkName: './pages/job_offer_search/employment' */).then(({ JobOfferSearchEmployment }) => {
            new JobOfferSearchEmployment().init();
            resolve();
          });
          break;
        case 'job_offers/employment_feature':
          import('./pages/job_offer_search/employment_feature' /* webpackChunkName: './pages/job_offer_search/employment_feature' */).then(({ JobOfferSearchEmploymentFeature }) => {
            new JobOfferSearchEmploymentFeature().init();
            resolve();
          });
          break;
        case 'job_offers/prefecture_feature':
          import('./pages/job_offer_search/prefecture_feature' /* webpackChunkName: './pages/job_offer_search/prefecture_feature' */).then(({ JobOfferSearchPrefectureFeature }) => {
            new JobOfferSearchPrefectureFeature().init();
            resolve();
          });
          break;
        case 'job_offers/prefecture_employment':
          import('./pages/job_offer_search/prefecture_employment' /* webpackChunkName: './pages/job_offer_search/prefecture_employment' */).then(({ JobOfferSearchPrefectureEmployment }) => {
            new JobOfferSearchPrefectureEmployment().init();
            resolve();
          });
          break;
        case 'job_offers/prefecture_employment_feature':
          import('./pages/job_offer_search/prefecture_employment_feature' /* webpackChunkName: './pages/job_offer_search/prefecture_employment_feature' */).then(({ JobOfferSearchPrefectureEmploymentFeature }) => {
            new JobOfferSearchPrefectureEmploymentFeature().init();
            resolve();
          });
          break;
        case 'job_offers/search_special':
          import('./pages/job_offer_search/special' /* webpackChunkName: './pages/job_offer_search/special' */).then(({ JobOfferSearchSpecial }) => {
            new JobOfferSearchSpecial().init();
            resolve();
          });
          break;
        case 'members/video_interview/replies/show':
        case 'members/video_interview/answers/new':
        case 'members/video_interview/answers/edit':
          import('./members-video_interview' /* webpackChunkName: './members-video_interview' */)
            .then(({ MembersVideoInterview }) => {
              new MembersVideoInterview().init();
              resolve();
            });
          break;
        case 'apply_job_offers/complete':
          import('./pages/apply_job_offer/index' /* webpackChunkName: './pages/apply_job_offer/index' */)
            .then(({ ApplyJobOffer }) => {
              new ApplyJobOffer().init();
              resolve();
            });
          break;
        case 'profile/additional/member_qualifications/index':
          import('./pages/additional_member_qualification/index' /* webpackChunkName: './pages/additional_member_qualification/index' */).then(({ AdditionalMemberQualification }) => {
            new AdditionalMemberQualification().init();
            resolve();
          });
          break;
        case 'members/resumes/index':
          import('./pages/resumes/index' /* webpackChunkName: './pages/resumes/index' */).then(({ ResumeIndex }) => {
            new ResumeIndex().init();
            resolve();
          });
          break;
        case 'members/resumes/basic/edit':
          import('./pages/resumes/basic/edit' /* webpackChunkName: './pages/resumes/basic/edit' */).then(({ ResumeBasicEdit }) => {
            new ResumeBasicEdit({ formManagers: this.formManagers }).init();
            resolve();
          });
          break;
        case 'members/resumes/education_backgrounds/edit':
          import('./pages/resumes/education_backgrounds/edit' /* webpackChunkName: './pages/resumes/education_backgrounds/edit' */).then(({ ResumeEducationBackgroundsEdit }) => {
            new ResumeEducationBackgroundsEdit({ formManagers: this.formManagers }).init();
            resolve();
          });
          break;
        case 'members/resumes/careers/edit':
          import('./pages/resumes/careers/edit' /* webpackChunkName: './pages/resumes/careers/edit' */).then(({ ResumeCareersEdit }) => {
            new ResumeCareersEdit({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/resumes/qualifications/edit':
          import('./pages/resumes/qualifications/edit' /* webpackChunkName: './pages/resumes/qualifications/edit' */).then(({ ResumeQualificationsEdit }) => {
            new ResumeQualificationsEdit({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/resumes/others/edit':
          import('./pages/resumes/others/edit' /* webpackChunkName: './pages/resumes/others/edit' */).then(({ ResumeOthersEdit }) => {
            new ResumeOthersEdit({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/resumes/reason_for_applying/edit':
          import('./pages/resumes/reason_for_applying/edit' /* webpackChunkName: './pages/resumes/reason_for_applying/edit' */).then(({ ResumeReasonForApplyingEdit }) => {
            new ResumeReasonForApplyingEdit({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/resumes/displayed_created_date/edit':
          import('./pages/resumes/displayed_created_date/edit' /* webpackChunkName: './pages/resumes/displayed_created_date/edit' */).then(({ ResumeDisplayedCreatedDateEdit }) => {
            new ResumeDisplayedCreatedDateEdit({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/messages/show':
          import('./pages/message_show/index' /* webpackChunkName: './pages/message_show/index' */).then(({ MessageShow }) => {
            new MessageShow({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'applications/new':
          import('./pages/application_new/index' /* webpackChunkName: './pages/application_new/index' */).then(({ ApplicationNew }) => {
            new ApplicationNew({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/sign_up':
          import('./pages/members_signup/index' /* webpackChunkName: './pages/members_signup/index' */).then(({ MembersSignUp }) => {
            new MembersSignUp({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/sign_up_step':
          import('./pages/members_signup/step' /* webpackChunkName: './pages/members_signup/step' */).then(({ MembersSignUpStep }) => {
            new MembersSignUpStep({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          break;
        case 'members/violation_reports':
          import('./pages/violation_report_new/index' /* webpackChunkName: './pages/violation_report_new/index' */).then(({ ViolationReportNew }) => {
            new ViolationReportNew().init();
            resolve();
          });
          break;
        case 'members/job_offers/favorite':
          import('./pages/members_favorite_job_offers/index' /* webpackChunkName: './pages/members_favorite_job_offers/index' */).then(({ MembersFavoriteJobOffersIndex }) => {
            new MembersFavoriteJobOffersIndex().init();
            resolve();
          });
          break;
        case 'members/recommend':
          import('./pages/members_recommend/index' /* webpackChunkName: './pages/members_recommend/index' */).then(({ MembersRecommend }) => {
            new MembersRecommend().init();
            resolve();
          });
          break;
        case 'members/surveys/new':
          import('./pages/survey_new/index' /* webpackChunkName: './pages/survey_new/index' */).then(({ SurveyNew }) => {
            new SurveyNew().init();
            resolve();
          });
          break;
        case 'indeed_finish_application':
          import('./indeed-finish-application-form' /* webpackChunkName: './indeed-finish-application-form' */).then(({ IndeedFinishApplicationForm }) => {
            new IndeedFinishApplicationForm({
              form: '.js-indeed-email-password-form',
            }).init();
            resolve();
          });
          break;
        case 'history':
          import('./pages/history/index' /* webpackChunkName: './pages/history/index' */).then(({ History }) => {
            new History().init();
            resolve();
          });
          break;
        case 'profile/additional/desired_job_categories/index':
          import('./pages/additional_completed_count/job_category' /* webpackChunkName: './pages/additional_completed_count/job_category' */).then(({ CompletedCountAdditionalJobCategory }) => {
            new CompletedCountAdditionalJobCategory().init();
            resolve();
          });
          break;
        // FIXME: app.lp.responsive.js の方に移したい #27911
        case 'static_pages/landing_pages/general_step':
        case 'static_pages/landing_pages/nurse_step':
        case 'static_pages/landing_pages/clerk_step':
        case 'static_pages/landing_pages/care_worker_step':
        case 'static_pages/landing_pages/childcare_worker_step':
        case 'static_pages/landing_pages/guide_scout':
          import('./pages/landing_pages/step' /* webpackChunkName: './pages/landing_pages/step' */).then(({ MembersSignUpStep }) => {
            new MembersSignUpStep({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          import('./pages/landing_pages/slider' /* webpackChunkName: './pages/landing_pages/slider' */).then(({ Slider }) => {
            new Slider().init();
            resolve();
          });
          break;
        case 'static_pages/landing_pages/general_booklet_step':
          import('./pages/landing_pages/four_step' /* webpackChunkName: './pages/landing_pages/four_step' */).then(({ MembersSignUpStep4 }) => {
            new MembersSignUpStep4({
              formManagers: this.formManagers,
            }).init();
            resolve();
          });
          import('./pages/landing_pages/slider' /* webpackChunkName: './pages/landing_pages/slider' */).then(({ Slider }) => {
            new Slider().init();
            resolve();
          });
          break;
        default:
          resolve();
          break;
      }
    });

    route.then(() => {
      LazyImage.load();
    });
  }
}

$(() => {
  const app = new App();

  app.init();
});
